import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Dimmer, Form, FormButton, Grid, InputOnChangeData, Loader, Message, Modal, Progress } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import agent from "../../agent";
import { getTZ } from "../../utils/common";
import BackupFileList from "./BackupFileList";
import BackupAutoSettings from "./BackupAutoSettings";
const BackupConfig = () => {
    // const [enabled, setEnabled] = useState(false);
    // const [changing, setChanging] = useState(false);
    const [backupSystemOk, setbackupSystemOk] = useState(false);
    const [backupSystemState, setBackupSystemState] = useState('-');
    const [backuping, setBackuping] = useState(false);
    const [loading, setLoading] = useState(true);
    // const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

    useEffect(() => {
        async function queryStatus() {
            let response = (await agent.BackupProvider.status());
            setBackupSystemState(response.status);
            setbackupSystemOk(response.success);
            setLoading(false);
        }
        queryStatus().then();
    }, []);

    async function executeBackup() {
        setBackuping(true);
        await agent.BackupProvider.execute();
        setBackuping(false);
        alert("Запущено резервное копирование. См. подробности в журнале!");
    }

    return (
        <div>
            {!loading &&
                <Form>
                    <Message color={backupSystemOk ? undefined : "red"}>
                        <Grid>
                            <Grid.Column width={10}>
                                <Message.Header>Состояние подсистемы резервного копирования:</Message.Header>
                                {backupSystemState}
                            </Grid.Column>
                            <Grid.Column width={6}>
                                {/* <Button
                                    fluid
                                    disabled={!backupSystemOk}
                                    loading={backuping}
                                    content={'Запустить резервное копирование'}
                                    color={'teal'}
                                    onClick={executeBackup}
                                /> */}
                            </Grid.Column>
                        </Grid>
                    </Message>
                    {backupSystemOk &&
                        <Grid>
                            <Grid.Column width={4}>
                                <BackupAutoSettings />


                            </Grid.Column>
                            <Grid.Column width={12}>
                                <BackupFileList />
                            </Grid.Column>
                        </Grid>
                    }
                </Form>}
            {loading && <Dimmer active inverted>
                <Loader inverted>Загрузка конфигурации</Loader>
            </Dimmer>}
        </div>);
}

export default BackupConfig;