import React, {ChangeEvent} from "react";
import styles from "./index.module.css";
import {Button, Form, Grid, Loader} from "semantic-ui-react";
import agent from "../../../agent";
import {Institution} from "../../dashboard";
import {Link, Redirect} from "react-router-dom";
import {Utils} from "../../../helpers/Utils";
import { AppMode, GeneralTitleCase, getGeneralTitle } from "../../../utils/common";

interface Props {
    match?: { params: { id: string; }, path: string; };
}

interface State {
    editable: boolean,
    _id: string,
    name: string,
    link?: string,
    active: boolean
    loading: boolean,
    saved: boolean,
    editMode: boolean;
}

class institutionEditor extends React.PureComponent<Props, State> {
    state: State = {
        editable: false,
        _id: '',
        name: '',
        link: '',
        active: true,
        loading: false,
        editMode: false,
        saved: false,
    };

    async componentDidMount() {
        if (this.props.match && this.props.match.path.includes('edit')) {
            this.setState({editMode: true, loading: true});
            await this.onMount();
        } else {
            this.setState({editable: true})
        }
    };

    onMount = async (): Promise<void> => {
        const institutionId: string = this.props.match ? this.props.match.params.id : '';
        const doc: Institution = await agent.InstitutionProvider.open(institutionId);

        if (doc) {
            this.setState({
                _id: doc._id,
                name: doc.name,
                link: doc.link,
                active: doc.active,
                loading: false,
            });
        }
    };

    // onCreate = async (): Promise<void> => {
    //     this.setState({loading: true});
    //
    //     await agent.InstitutionProvider.create({
    //         _id: '',
    //         name: this.state.name
    //     });
    //
    //     this.setState({loading: false, saved: true});
    // };

    onEdit = async () => {
        this.setState({loading: true});

        await agent.InstitutionProvider.edit({
            _id: this.state._id,
            name: this.state.name,
            link: this.state.link,
            active: this.state.active
        }).then((doc: Institution) => {
            this.setState({
                _id: doc._id,
                name: doc.name,
                link: doc.link,
                active: doc.active,
                editable: false
            });
        });

        this.setState({loading: false, saved: true});
    };

    isSaveDisabled = (): boolean => {
        return !this.state.name
    };

    render() {
        const entityLabel = getGeneralTitle(GeneralTitleCase.showInstitutionsButtonBot);
        //const headerLabel: string = this.state.editMode ? `Данные медицинского учреждения` : `Создание ${entityLabel}`;
        const headerLabel: string = `Данные медицинского учреждения`

        return (
            this.state.saved ?
                <Redirect to={'/dashboard/institutions'}/>
                :
                <div className={styles.institutionInfoContent}>
                    <div className={styles.institutionInfoHeader}>
                        {headerLabel}
                    </div>
                    {
                        this.state.loading ?
                            <Loader active/>
                            :
                            <Form>
                                <Grid>
                                    <Grid.Column width={4}>
                                        <Form.Input label={`Наименование`}
                                                    value={this.state.name}
                                                    readOnly={true}
                                                    disabled={this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({name: e.target.value})}
                                        />
                                        <Form.Input label={`Ссылка`}
                                                    value={this.state.link}
                                                    readOnly={true}
                                                    disabled={this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({link: e.target.value})}
                                        />
                                        <Form.Checkbox label={`Активность кнопки в боте`}
                                                       checked={this.state.active}
                                                       disabled={!this.state.editable}
                                                       onChange={(event, data) => this.setState({ active: data.checked ? true : false })}
                                        />
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                        <div className={styles.institutionEditorButtons}>

                                            {
                                                this.state.editable ?
                                                    <Button
                                                        className={styles.button}
                                                        color={'green'}
                                                        type={'submit'}
                                                        content={'Сохранить'}
                                                        disabled={this.isSaveDisabled()}
                                                        onClick={this.onEdit}
                                                    />
                                                    :
                                                    <Button
                                                        className={styles.button}
                                                        color={'teal'}
                                                        type={'submit'}
                                                        content={'Изменить'}
                                                        onClick={() => this.setState({editable: true})}
                                                    />
                                            }

                                            <Link to={`/dashboard/institutions`}>
                                                <Button
                                                    className={styles.button}
                                                    color={'grey'}
                                                    type={'submit'}
                                                    content={'Назад'}
                                                />
                                            </Link>

                                        </div>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                    }
                </div>
        )
    }
}

export default institutionEditor;