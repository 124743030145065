import React, {ChangeEvent, useEffect, useState} from "react";
import {
    Button,
    Container,
    Dimmer,
    Form,
    FormButton,
    Grid,
    Header,
    InputOnChangeData,
    Loader,
    Message,
    Modal
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import agent from "../../agent";
import {getTZ} from "../../utils/common";
import BackupFileList from "./BackupFileList";
import ReactInputMask from "react-input-mask";

const BackupAutoSettings = () => {
    const [settingEnabled, setSettingEnabled] = useState(false);
    const [time, setTime] = useState<string | undefined>();
    const [hour, setHour] = useState<number | undefined>();
    const [minute, setMinute] = useState<number | undefined>();
    const [changed, setChanged] = useState(false);
    const [maxBackupCount, setMaxBackupCount] = useState(0);
    const [maxBackupDays, setMaxBackupDays] = useState(0);
    const [currentSettings, setCurrentSettings] = useState<any>({});

    useEffect(updateChanged, [settingEnabled, hour, minute, maxBackupCount, maxBackupDays, currentSettings]);

    useEffect(() => {
        query();
    }, []);

    function updateChanged() {
        setChanged(currentSettings.enabled != settingEnabled
            || currentSettings.maxBackupCount != maxBackupCount
            || currentSettings.maxBackupDays != maxBackupDays
            || currentSettings.hour != hour
            || currentSettings.minute != minute);
    }

    async function query() {
        let response = (await agent.ConfigProvider.get());
        setSettingEnabled(response.autoBackupSettings.enabled);
        let hour = response.autoBackupSettings.hour.toString();
        if (hour.length < 2) {
            hour = '0' + hour;
        }
        let minute = response.autoBackupSettings.minute.toString();
        if (minute.length < 2) {
            minute = '0' + minute;
        }
        setCurrentSettings(response.autoBackupSettings);
        setSettingEnabled(response.autoBackupSettings.enabled);
        setHour(hour);
        setMinute(minute);
        setMaxBackupCount(response.autoBackupSettings.maxBackupCount);
        setMaxBackupDays(response.autoBackupSettings.maxBackupDays);
        setTime(hour.toString() + ":" + minute.toString());
    }

    return (
        <div style={{margin: "0 0 3em"}}>

            <Header as={"h4"}>Автоматическое резервное копирование</Header>
            <Form.Checkbox label={'Выполнять ежедневное автоматическое резервное копирование'}
                           checked={settingEnabled}
                           disabled={!window.config?.backupSettingsEnabled}
                           onChange={(el, v) => {
                               console.log(v.checked!!);
                               setSettingEnabled(v.checked!!)
                           }}/>

            <Form.Field>
                <label>Время выполнения резервного копирования</label>
            </Form.Field>
            <Form.Field width={4}>
                <ReactInputMask
                    mask={'99:99'}
                    disabled={!window.config?.backupSettingsEnabled}
                    alwaysShowMask={false}
                    value={time}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        let time: string = e.target.value;
                        const hours = time.substring(0, 2);
                        const minutes = time.substring(3, 5);
                        if (parseInt(hours, 10) >= 24) {
                            time = `23:${minutes}`;
                        } else if (parseInt(minutes, 10) >= 60) {
                            time = `${hours}:59`;
                        }
                        setTime(time);
                        setHour(parseInt(hours, 10));
                        setMinute(parseInt(minutes, 10));
                    }}
                />
            </Form.Field>

            {/* <Form.Field>
                <label>Максимальное число сохраненных резервных копий</label>
            </Form.Field>
            <Form.Input value={maxBackupCount} width={4}
                onChange={(e, v) => {
                    if (v.value)
                        setMaxBackupCount(v.value ? parseInt(v.value) : 0);
                }}
            ></Form.Input> */}
            <Form.Field>
                <label>Сколько дней хранить резервные копии</label>
            </Form.Field>
            <Form.Input value={maxBackupDays} width={4}
                        disabled={!window.config?.backupSettingsEnabled}
                        onChange={(e, v) => {
                            if (v.value)
                                setMaxBackupDays(v.value ? parseInt(v.value) : 0);
                        }}
            ></Form.Input>
            {window.config?.backupSettingsEnabled && changed &&
            <Button fluid
                    content={'Сохранить конфигурацию'}
                    color={'blue'}
                    onClick={(async (event, data) => {
                        let config = (await agent.ConfigProvider.get());
                        config.autoBackupSettings.enabled = settingEnabled;
                        config.autoBackupSettings.hour = hour;
                        config.autoBackupSettings.minute = minute;
                        config.autoBackupSettings.maxBackupCount = maxBackupCount;
                        config.autoBackupSettings.maxBackupDays = maxBackupDays;
                        await agent.ConfigProvider.edit(config);
                        query();
                    })}/>}

        </div>);
}

export default BackupAutoSettings;