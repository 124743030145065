import React from "react";
import FullCalendar from "@fullcalendar/react";
import ruLocale from "@fullcalendar/core/locales/ru";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import styles from "./index.module.css";
import {CalendarEvent, PatientEventFull} from "../workTablePatients/EditorTabs/CureProgramTab";
import agent, {PaginateOptions} from "../../agent";
import {EventRepeater} from "../../helpers/CalendarEventsRepeater";
import {Dropdown, DropdownItemProps, DropdownProps, Form} from "semantic-ui-react";
import {DoctorPaginationResponse} from "../workTableDoctors/DoctorsList";
import {DeptPaginationResponse} from "../workTableDepts/DeptList";
import {Dept, Doctor} from "../dashboard";
import {AppMode, DoctorTitleCase, GeneralTitleCase, getDoctorId, getDoctorTitle, getGeneralTitle} from "../../utils/common";
import {Utils} from "../../helpers/Utils";
import {EventClickArg} from "@fullcalendar/core";
import MediaQuery from "react-responsive";
import {firstLetterToUpperCase} from "../workTablePatients/PatientsList";
import {hasRole, UserRole} from "../../helpers/UserRole";

interface Props {
}

interface State {
    events: CalendarEvent[],
    rangeCount: number,
    doctorid: string,
    doctors: Doctor[],
    deptId?: string,
    depts: Dept[],
    doctorDisabled: boolean
}

class Hospitalizations extends React.PureComponent<Props, State> {
    state: State = {
        events: [],
        rangeCount: 0,
        doctorid: "",
        doctors: [],
        depts: [],
        doctorDisabled: false
    }

    changeDatesRangeHandler = (arg: { start: Date, end: Date }) => {
        const daysCount = Math.round(Math.abs((arg.start.getTime() - arg.end.getTime()) / (24 * 60 * 60 * 1000)));
        let dateStart = arg.start;
        let dateEnd = arg.end;
        if (daysCount === 42) {
            const middleDayMonth = new Date(dateStart);
            middleDayMonth.setDate(dateStart.getDate() + 8);
            dateStart.setFullYear(middleDayMonth.getFullYear(), middleDayMonth.getMonth(), 1);
            dateEnd.setFullYear(middleDayMonth.getFullYear(), middleDayMonth.getMonth() + 1, 1);
        }
        const rangeCount = this.state.events.filter((e: CalendarEvent) => {
            return dateEnd.getTime() > new Date(e.start).getTime() && new Date(e.start).getTime() > dateStart.getTime();
        }).length;
        this.setState({rangeCount: rangeCount});
    }

    getDoctorList = async (): Promise<void> => {
        // this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: 10000,
            page: 1,
            search: "",
            sort: "surname"
        };

        const responseDoctors: DoctorPaginationResponse = await agent.DoctorProvider.getList(paginateOptions);
        const responseDepts: DeptPaginationResponse = await agent.DeptProvider.getList(paginateOptions);

        if (responseDoctors && responseDepts) {
            this.setState({
                doctors: responseDoctors.docs,
                depts: responseDepts.docs
            });
        } else {
            //  this.setState({loading: false});
        }
    };

    async componentDidMount() {
        await this.getDoctorList();

        let doctorid = await getDoctorId();
        if (doctorid)
            this.setState({doctorid: doctorid, doctorDisabled: true});

        await this.load();
    };

    async load() {
        const hospitalizations: PatientEventFull[] = await agent.PatientEventProvider.getHospitalizations(
            this.state.deptId, this.state.doctorid
        );

        let calendarEvents: CalendarEvent[] = [];
        if (hospitalizations) {
            hospitalizations.forEach((item: PatientEventFull) => {

                const eventArray = EventRepeater(item, `${item.patient.surname} ${item.patient.name && item.patient.name[0]}.${item.patient.middlename && item.patient.middlename[0]}.`,
                    `/dashboard/patients/${item.patient._id}/edit/patientInfo`);
                calendarEvents.push(...eventArray);
            });

            this.setState({events: calendarEvents});

            const now = new Date();
            this.changeDatesRangeHandler({
                start: new Date(now.getFullYear(), now.getMonth(), 1),
                end: new Date(now.getFullYear(), now.getMonth() + 1, 1),
            });
        }
    }

    render() {
        return (
            <div className={styles.componentWrapper}>
                <div style={{padding: "0px 332px 0 190px"}}>
                    <div style={{textAlign: "center"}}>Количество: {this.state.rangeCount}</div>
                </div>
                <div>
                    <Form>
                        <Form.Group>
                            <Form.Field width={"3"}>
                                <label>
                                    {firstLetterToUpperCase(getDoctorTitle(DoctorTitleCase.im))}
                                </label>
                                <Dropdown
                                    disabled={this.state.doctorDisabled}
                                    selection
                                    clearable={true}
                                    value={this.state.doctorid}
                                    onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                        this.setState({doctorid: data.value != null ? data.value.toString() : ""},
                                            this.load)
                                    }}
                                    options={this.state.doctors.map((doctor) => ({
                                        key: doctor._id,
                                        text: doctor.surname + " " + doctor.name[0] + "." + doctor.middlename[0] + ".",
                                        value: doctor._id,
                                    } as DropdownItemProps))}
                                />
                            </Form.Field>
                            <Form.Field width={"3"}>
                                <label>
                                    {firstLetterToUpperCase(getGeneralTitle(GeneralTitleCase.departmantim))}
                                </label>
                                <Dropdown
                                    disabled={this.state.doctorDisabled}
                                    selection
                                    clearable={true}
                                    value={this.state.deptId}
                                    onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                        this.setState({deptId: (data.value && data.value !== "" ? data.value.toString() : undefined)},
                                            this.load)
                                    }}
                                    options={this.state.depts.map((dept) => ({
                                        key: dept._id,
                                        text: dept.name,
                                        value: dept._id,
                                    } as DropdownItemProps))}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </div>
                <MediaQuery minWidth={1224}>
                    <FullCalendar
                        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin]}
                        events={this.state.events}
                        initialView="dayGridMonth"
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                        }}
                        locale={ruLocale}
                        displayEventTime={false}
                        datesSet={this.changeDatesRangeHandler}
                        eventDidMount={(arg) => {
                            arg.el.setAttribute("title", arg.event.title);
                        }}
                        eventClick={(event: EventClickArg) => {
                            if (event.event.url) {
                                event.jsEvent.preventDefault();
                                window.open(event.event.url, "_blank");
                            }
                        }}
                    />
                </MediaQuery>
                <MediaQuery maxWidth={1224}>
                    <FullCalendar
                        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin]}
                        events={this.state.events}
                        initialView="listMonth"
                        views={{
                            year: {
                                type: 'listYear',
                                buttonText: 'Повестка год'
                            },
                            month: {
                                type: 'listMonth',
                                buttonText: 'Повестка месяц'
                            }
                        }}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'month,year'
                        }}
                        locale={ruLocale}
                        displayEventTime={false}
                        datesSet={this.changeDatesRangeHandler}
                        eventDidMount={(arg) => {
                            arg.el.setAttribute("title", arg.event.title);
                        }}
                        eventClick={(event: EventClickArg) => {
                            if (event.event.url) {
                                event.jsEvent.preventDefault();
                                window.open(event.event.url, "_blank");
                            }
                        }}
                    />
                </MediaQuery>

            </div>
        );
    }
}

export default Hospitalizations;