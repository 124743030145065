import React, {ChangeEvent, SyntheticEvent} from "react";
import styles from "./index.module.css";
import {Button, Form, Grid, Loader} from "semantic-ui-react";
import agent from "../../agent";
import {Link, Redirect} from "react-router-dom";
import {Test} from "./TestModel";
import {TextAreaProps} from "semantic-ui-react/dist/commonjs/addons/TextArea/TextArea";
import TestIndicatorList from "./TestIndicatorList";
import {Utils} from "../../helpers/Utils";
import { AppMode } from "../../utils/common";
import {hasRole, UserRole} from "../../helpers/UserRole";

interface Props {
    match?: { params: { id: string; }, path: string; };
}

interface State extends Test {
    editable: boolean,
    loading: boolean,
    saved: boolean,
    editMode: boolean;
}

class TestEditPage extends React.PureComponent<Props, State> {
    state: State = {
        editable: false,
        indicators: [],
        dept: {
            _id: "",
            name: ""
        },
        _id: "",
        name: "",
        loading: false,
        editMode: false,
        saved: false,
    };

    async componentDidMount() {
        if (this.props.match && this.props.match.path.includes('edit')) {
            this.setState({editMode: true, loading: true});
            await this.onMount();
        } else {
            this.setState({editable: true})
        }
    };

    onMount = async (): Promise<void> => {
        const testId: string = this.props.match ? this.props.match.params.id : '';
        const doc: Test = await agent.TestProvider.open(testId);

        if (doc) {
            this.setState(doc);
            this.setState({
                loading: false,
            });
        }
    };

    onCreate = async (): Promise<void> => {
        this.setState({loading: true});
        await agent.TestProvider.create(this.state as Test);
        this.setState({loading: false, saved: true});
    };

    onEdit = async () => {
        this.setState({loading: true});

        await agent.TestProvider.edit(this.state).then((doc: Test) => {
            this.setState(doc);
        });

        this.setState({loading: false, saved: true, editable: false});
    };

    isSaveDisabled = (): boolean => {
        return !this.state.name
    };

    render() {
        const headerLabel: string = this.state.editMode ? `Тип ${Utils.vomode()!=AppMode.PATIENT ? 'документа' : 'анализа'}` : `Создание типа ${Utils.vomode()!=AppMode.PATIENT ? 'документа' : 'анализа'}`;

        return (
            this.state.saved ?
                <Redirect to={'/dashboard/test'}/>
                :
                <div className={styles.testInfoContent}>
                    <div className={styles.testInfoHeader}>
                        {headerLabel}
                    </div>
                    {
                        this.state.loading ?
                            <Loader active/>
                            :
                            <Form>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <Form.Input label={'Наименование'}
                                                        value={this.state.name}
                                                        readOnly={!this.state.editable}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            this.setState({name: e.target.value});
                                                        }}
                                            />
                                        </Grid.Column>

                                        <Grid.Column width={3}>
                                            <div className={styles.testEditorButtons}>
                                                {
                                                    this.state.editable ?
                                                        <Button
                                                            className={styles.button}
                                                            color={'green'}
                                                            type={'submit'}
                                                            content={'Сохранить'}
                                                            disabled={this.isSaveDisabled()}
                                                            onClick={this.state.editMode ? this.onEdit : this.onCreate}
                                                        />
                                                        :
                                                        <Button
                                                            className={styles.button}
                                                            color={'teal'}
                                                            type={'submit'}
                                                            content={'Изменить'}
                                                            onClick={() => this.setState({editable: true})}
                                                            disabled={hasRole(UserRole.OBSERVER)}
                                                        />
                                                }

                                                {!this.state.editable &&
                                                <Link to={`/dashboard/test`}>
                                                    <Button
                                                        className={styles.button}
                                                        color={'grey'}
                                                        type={'submit'}
                                                        content={'Назад'}
                                                    />
                                                </Link>}

                                                {this.state.editable &&
                                                    <Button
                                                        className={styles.button}
                                                        color={'grey'}
                                                        onClick={() => {
                                                            this.setState({editable: false, saved: true});
                                                        }}
                                                        content={'Отмена'}
                                                    />}
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <Form.TextArea label={'Комментарий'}
                                                           value={this.state.comment?.toString()}
                                                           readOnly={!this.state.editable}
                                                           onChange={(e: SyntheticEvent, data: TextAreaProps) => {
                                                               this.setState({comment: data.value?.toString()});
                                                           }}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={10}>
                                            <Form.Field width={"16"}>
                                                <label>
                                                        <Form.Field width={"5"}>
                                                            Показатели {Utils.vomode()!=AppMode.PATIENT ? 'документа' : 'анализа'}
                                                        </Form.Field>
                                                </label>
                                                <TestIndicatorList indicators={this.state.indicators} editing={this.state.editable}/>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                    }
                </div>
        )
    }
}

export default TestEditPage;