import React, {ChangeEvent} from "react";
import styles from "./index.module.css";
import {Button, DropdownItemProps, DropdownProps, Form, Grid, Loader} from "semantic-ui-react";
import agent, {PaginateOptions} from "../../../agent";
import {Observer} from "../../dashboard";
import {Link, Redirect} from "react-router-dom";

interface Props {
    match?: { params: { id: string; }, path: string; };
}

interface State {
    editable: boolean,
    _id: string,
    username: string,
    name: string,
    middlename: string,
    surname: string,
    tel: string,
    email: string,
    loading: boolean,
    saved: boolean,
    editMode: boolean;
    login?: string;
    password?: string;
    password2?: string;
    passwordChangeMode: boolean;
    passwordError?: string;
    password2Error?: string;
    roleEnableScan: boolean,
    roleEnableFileUpload?: boolean
}

class ObserverEditor extends React.PureComponent<Props, State> {
    state: State = {
        editable: false,
        _id: '',
        username: '',
        name: '',
        middlename: '',
        surname: '',
        tel: '',
        email: '',
        loading: false,
        editMode: false,
        saved: false,
        passwordChangeMode: false,
        roleEnableScan: false,
        roleEnableFileUpload: false
    };

    async componentDidMount() {
        if (this.props.match && this.props.match.path.includes('edit')) {
            this.setState({editMode: true, loading: true, passwordChangeMode: false});
            await this.onMount();
        } else {
            this.setState({editable: true, loading: false, passwordChangeMode: true})
        }
    };

    onMount = async (): Promise<void> => {
        const observerId: string = this.props.match ? this.props.match.params.id : '';
        const doc: Observer = await agent.ObserverProvider.open(observerId);

        if (doc) {
            this.setState({
                _id: doc._id,
                username: doc.username,
                name: doc.name,
                middlename: doc.middlename,
                surname: doc.surname,
                tel: doc.tel,
                email: doc.email,
                loading: false,
                login: doc.login,
                passwordChangeMode: !doc.hasPassword,
                roleEnableScan: !!doc.roleEnableScan,
                roleEnableFileUpload: !!doc.roleEnableFileUpload
            });
        }
    };

    onCreate = async (): Promise<void> => {
        if (!this.checkPasswords()) return;
        this.setState({loading: true});

        await agent.ObserverProvider.create({
            _id: '',
            username: this.state.username,
            name: this.state.name,
            middlename: this.state.middlename,
            surname: this.state.surname,
            tel: this.state.tel,
            email: this.state.email,
            login: this.state.login,
            password: this.state.password,
            roleEnableScan: this.state.roleEnableScan,
            roleEnableFileUpload: this.state.roleEnableFileUpload
        });

        this.setState({loading: false, saved: true});
    };

    onEdit = async () => {
        if (!this.checkPasswords()) return;
        this.setState({loading: true});

        await agent.ObserverProvider.edit({
            _id: this.state._id,
            username: this.state.username,
            name: this.state.name,
            middlename: this.state.middlename,
            surname: this.state.surname,
            tel: this.state.tel,
            email: this.state.email,
            login: this.state.login,
            password: this.state.password,
            roleEnableScan: this.state.roleEnableScan,
            roleEnableFileUpload: this.state.roleEnableFileUpload
        }).then((doc: Observer) => {
            this.setState({
                _id: doc._id,
                username: doc.username,
                name: doc.name,
                middlename: doc.middlename,
                surname: doc.surname,
                tel: doc.tel,
                email: doc.email,
                editable: false,
                login: doc.login,
                roleEnableScan: !!doc.roleEnableScan,
                roleEnableFileUpload: !!doc.roleEnableFileUpload
            });
        });

        this.setState({loading: false, saved: true});
    };

    isSaveDisabled = (): boolean => {
        return !this.state.surname
    };

    checkPasswords = (): boolean => {
        if (this.state.editable && (window.config?.useObserverAuthenticationByPassword === true) && this.state.passwordChangeMode) {
            if (!!this.state.login) {
                if (!this.state.password) {
                    this.setState({passwordError: "Задайте пароль пользователя"});
                    return false;
                } else if ((this.state.password?.length ?? 0) < 4) {
                    this.setState({passwordError: "Минимальная длина пароля - 4 символа!"});
                    return false;
                } else if (this.state.password! !== this.state.password2) {
                    this.setState({password2Error: "Введенные пароли не совпадают!"});
                    return false;
                }
            }
        }
        return true;
    };

    render() {
        const headerLabel: string = this.state.editMode ? `Данные наблюдателя` : `Создание наблюдателя`;
        const showLogin: boolean = window.config?.useObserverAuthenticationByPassword === true;
        const showPassword: boolean = showLogin && this.state.editable && this.state.passwordChangeMode;

        return (
            this.state.saved ?
                <Redirect to={'/dashboard/observers'}/>
                :
                <div className={styles.observerInfoContent}>
                    <div className={styles.observerInfoHeader}>
                        {headerLabel}
                    </div>
                    {
                        this.state.loading ?
                            <Loader active/>
                            :
                            <Form>
                                <Grid>
                                    <Grid.Column width={4}>
                                        <Form.Input label={'Фамилия'}
                                                    value={this.state.surname}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({surname: e.target.value})}
                                        />
                                        <Form.Input label={'Имя'}
                                                    value={this.state.name}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({name: e.target.value})}
                                        />
                                        <Form.Input label={'Отчество'}
                                                    value={this.state.middlename}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({middlename: e.target.value})}
                                        />
                                        <Form.Input label={'Электронная почта'}
                                                    value={this.state.email}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({email: e.target.value})}
                                        />
                                        <Form.Input label={'Номер телефона'}
                                                    value={this.state.tel}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({tel: e.target.value})}
                                        />
                                        <Form.Input label={'Telegram username'}
                                                    value={this.state.username}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({username: e.target.value})}
                                        />

                                    </Grid.Column>

                                    <Grid.Column width={6}>
                                        <div className={styles.observerEditorButtons}>

                                            {
                                                this.state.editable ?
                                                    <Button
                                                        className={styles.button}
                                                        color={'green'}
                                                        type={'submit'}
                                                        content={'Сохранить'}
                                                        disabled={this.isSaveDisabled()}
                                                        onClick={this.state.editMode ? this.onEdit : this.onCreate}
                                                    />
                                                    :
                                                    <Button
                                                        className={styles.button}
                                                        color={'teal'}
                                                        type={'submit'}
                                                        content={'Изменить'}
                                                        onClick={() => this.setState({editable: true})}
                                                    />
                                            }

                                            <Link to={`/dashboard/observers`}>
                                                <Button
                                                    className={styles.button}
                                                    color={'grey'}
                                                    type={'submit'}
                                                    content={'Назад'}
                                                />
                                            </Link>

                                        </div>
                                        <div className={styles.observerEditorPassword}>
                                            {showLogin && <Form.Input label={'Логин'}
                                                                      value={this.state.login}
                                                                      readOnly={!this.state.editable}
                                                                      onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({login: e.target.value})}

                                            />}

                                            {showPassword && <div>
                                                <Form.Input label={'Введите пароль'}
                                                            type={"password"}
                                                            value={this.state.password}
                                                            readOnly={!this.state.editable}
                                                            error={this.state.passwordError}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({password: e.target.value})}
                                                />
                                                <Form.Input label={'Повторите пароль'}
                                                            type={"password"}
                                                            value={this.state.password2}
                                                            readOnly={!this.state.editable}
                                                            error={this.state.password2Error}
                                                            onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({password2: e.target.value})}
                                                /></div>}

                                            {this.state.editable && showLogin && !this.state.passwordChangeMode &&
                                            <Form.Button content={"Сменить пароль"} color={"orange"} onClick={() => {
                                                this.setState({passwordChangeMode: true});
                                            }}/>}
                                        </div>

                                    </Grid.Column>
                                </Grid>
                            </Form>
                    }
                </div>
        )
    }
}

export default ObserverEditor;