import jwt_decode from "jwt-decode";
import {getToken} from "../utils/common";

export enum UserRole {
    ADMIN = "ADMIN",
    DOCTOR = "DOCTOR",
    OBSERVER = "OBSERVER"
}

export type JwtPayload = {
    role: UserRole,
    username: string,
    userid?: string,
    roleEnableScan: boolean,
    roleEnableFileUpload: boolean
}

export const hasRole = (requiredRole: UserRole): boolean => {
    return requiredRole === (jwt_decode(getToken()) as JwtPayload).role;
}
