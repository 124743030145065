import React, {ChangeEvent} from "react";
import styles from "./index.module.css";
import {Button, Form, Grid, Loader} from "semantic-ui-react";
import agent from "../../agent";
import {Dept} from "../dashboard";
import {Link, Redirect} from "react-router-dom";
import {Utils} from "../../helpers/Utils";
import { AppMode, GeneralTitleCase, getGeneralTitle } from "../../utils/common";

interface Props {
    match?: { params: { id: string; }, path: string; };
}

interface State {
    editable: boolean,
    _id: string,
    name: string,
    loading: boolean,
    saved: boolean,
    editMode: boolean;
}

class deptEditor extends React.PureComponent<Props, State> {
    state: State = {
        editable: false,
        _id: '',
        name: '',
        loading: false,
        editMode: false,
        saved: false,
    };

    async componentDidMount() {
        if (this.props.match && this.props.match.path.includes('edit')) {
            this.setState({editMode: true, loading: true});
            await this.onMount();
        } else {
            this.setState({editable: true})
        }
    };

    onMount = async (): Promise<void> => {
        const deptId: string = this.props.match ? this.props.match.params.id : '';
        const doc: Dept = await agent.DeptProvider.open(deptId);

        if (doc) {
            this.setState({
                _id: doc._id,
                name: doc.name,
                loading: false,
            });
        }
    };

    onCreate = async (): Promise<void> => {
        this.setState({loading: true});

        await agent.DeptProvider.create({
            _id: '',
            name: this.state.name
        });

        this.setState({loading: false, saved: true});
    };

    onEdit = async () => {
        this.setState({loading: true});

        await agent.DeptProvider.edit({
            _id: this.state._id,
            name: this.state.name
        }).then((doc: Dept) => {
            this.setState({
                _id: doc._id,
                name: doc.name,
                editable: false
            });
        });

        this.setState({loading: false, saved: true});
    };

    isSaveDisabled = (): boolean => {
        return !this.state.name
    };

    render() {
        const entityLabel = getGeneralTitle(GeneralTitleCase.departmanta);
        const headerLabel: string = this.state.editMode ? `Данные ${entityLabel}` : `Создание ${entityLabel}`;

        return (
            this.state.saved ?
                <Redirect to={'/dashboard/depts'}/>
                :
                <div className={styles.deptInfoContent}>
                    <div className={styles.deptInfoHeader}>
                        {headerLabel}
                    </div>
                    {
                        this.state.loading ?
                            <Loader active/>
                            :
                            <Form>
                                <Grid>
                                    <Grid.Column width={4}>
                                        <Form.Input label={`Наименование ${entityLabel}`}
                                                    value={this.state.name}
                                                    readOnly={!this.state.editable}
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({name: e.target.value})}
                                        />
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                        <div className={styles.deptEditorButtons}>

                                            {
                                                this.state.editable ?
                                                    <Button
                                                        className={styles.button}
                                                        color={'green'}
                                                        type={'submit'}
                                                        content={'Сохранить'}
                                                        disabled={this.isSaveDisabled()}
                                                        onClick={this.state.editMode ? this.onEdit : this.onCreate}
                                                    />
                                                    :
                                                    <Button
                                                        className={styles.button}
                                                        color={'teal'}
                                                        type={'submit'}
                                                        content={'Изменить'}
                                                        onClick={() => this.setState({editable: true})}
                                                    />
                                            }

                                            <Link to={`/dashboard/depts`}>
                                                <Button
                                                    className={styles.button}
                                                    color={'grey'}
                                                    type={'submit'}
                                                    content={'Назад'}
                                                />
                                            </Link>

                                        </div>
                                    </Grid.Column>
                                </Grid>
                            </Form>
                    }
                </div>
        )
    }
}

export default deptEditor;