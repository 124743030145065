import React, {ChangeEvent} from "react";
import styles from "./index.module.css";
import {Button, Form, Icon, Loader, Menu, Modal, Table} from 'semantic-ui-react';
import agent, {PaginateOptions} from "../../agent";
import {Link} from "react-router-dom";
import {Test} from "./TestModel";
import {Utils} from "../../helpers/Utils";
import { AppMode } from "../../utils/common";
import {hasRole, UserRole} from "../../helpers/UserRole";

interface Props {
    history?: History;
    match?: { url: string };
}

interface State {
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
    items: Test[],
    loading: boolean,
    openRemoveModal: boolean,
    id: string,
    sortColumn: string,
    sortOrder: 'ascending' | 'descending' | undefined,
    searchValue: string,
}

export interface TestPaginationResponse {
    docs: Test[],
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
}

class TestListPage extends React.PureComponent<Props, State> {
    state: State = {
        totalDocs: 0,
        limit: 0,
        page: 1,
        totalPages: 1,
        hasNextPage: false,
        nextPage: 0,
        hasPrevPage: false,
        prevPage: 0,
        pagingCounter: 0,
        items: [],
        loading: false,
        openRemoveModal: false,
        id: '',
        sortColumn: 'name',
        sortOrder: 'ascending',
        searchValue: '',
    };

    async componentDidMount() {
        await this.getTestsList(1, 'name');
    };

    getTestsList = async (goToPage?: number, sortBy?: string, searchString?: string): Promise<void> => {
        const searchTo: string = searchString ? searchString : '';

        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: 10,
            page: goToPage ? goToPage : 1,
            sort: sortBy,
            search: searchTo,
        };

        const response: TestPaginationResponse = await agent.TestProvider.getList(paginateOptions);

        if (response) {
            this.setState({
                items: response.docs,
                totalDocs: response.totalDocs,
                limit: response.limit,
                page: response.page,
                totalPages: response.totalPages,
                hasNextPage: response.hasNextPage,
                nextPage: response.nextPage,
                hasPrevPage: response.hasNextPage,
                prevPage: response.prevPage,
                pagingCounter: response.pagingCounter,
                loading: false
            });
        } else {
            this.setState({loading: false});
        }
    };

    removeTest = async () => {
        this.setState({loading: true});

        await agent.TestProvider.remove(this.state.id);

        this.setState({
            id: '',
            openRemoveModal: false
        });

        await this.getTestsList();
    };
    timeout: NodeJS.Timeout | undefined;

    search = (): void => {
        this.setState({loading: true});
        this.getTestsList(this.state.page, '', this.state.searchValue);
    }

    switchSortOrder = (orderString: string): string => {
        switch (this.state.sortOrder) {
            case 'ascending':
                this.setState({sortOrder: 'descending'});

                return `-${orderString}`;

            case 'descending':
                this.setState({sortOrder: 'ascending'});

                return orderString;

            default:
                return '';
        }
    };

    sortColumn = async (columnName: string): Promise<void> => {
        let sortString: string = columnName;

        if (this.state.sortColumn === columnName) {
            sortString = `${this.switchSortOrder(columnName)}`;
        } else {
            this.setState({sortOrder: 'ascending'});
        }

        this.getTestsList(this.state.page, sortString, this.state.searchValue)
            .then(() =>
                this.setState({sortColumn: columnName}));
    };

    render() {
        const currentPath: string = this.props.match ? this.props.match.url : '';

        return (
            <div className={styles.table}>

                {this.removeModalContent()}

                <div className={styles.header}>
                    Типы {Utils.vomode()!=AppMode.PATIENT ? 'документов' : 'анализов'}
                    {!hasRole(UserRole.OBSERVER) &&
                        <Link to={`${currentPath}/create`}>
                            <Button
                                className={styles.createButton}
                                size={'large'}
                                color={'green'}
                                type={'submit'}
                            >
                                Добавить
                            </Button>
                        </Link>
                    }
                </div>

                {
                    this.state.loading ?
                        <Loader active>Список загружается...</Loader>
                        :
                        <div className={styles.list}>
                            <Form>
                                <Form.Group>
                                    <Form.Input
                                        placeholder='Поиск'
                                        iconPosition='left'
                                        icon={<Icon name='search'/>}
                                        value={this.state.searchValue}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            this.setState({searchValue: e.target.value})
                                        }}
                                    />
                                    <Form.Button primary content='Поиск' onClick={() => {
                                        this.search()
                                    }}/>
                                    <Form.Button basic color='orange' content='Сбросить'
                                                 onClick={() => {
                                                     this.setState({searchValue: ""}, this.search)
                                                 }}/>
                                </Form.Group>
                            </Form>

                            <div className={styles.container}>
                                <Table celled selectable striped verticalAlign='middle' color={"teal"}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'name' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('name')}>Наименование {Utils.vomode()!=AppMode.PATIENT ? 'документа' : 'анализа'}
                                            </Table.HeaderCell>
                                            {!hasRole(UserRole.OBSERVER) && <Table.HeaderCell/>}
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            this.state.items && this.state.items.map((el, index) =>
                                                (
                                                    <Table.Row style={{cursor: "pointer"}}
                                                               key={`key-${index}-${el._id}`}>

                                                        <Table.Cell floated={'left'}>
                                                            <Link className={styles.nameColumn}
                                                                  to={`${currentPath}/${el._id}/edit`}>
                                                                {`${el.name}`}
                                                            </Link>
                                                        </Table.Cell>

                                                        {!hasRole(UserRole.OBSERVER) &&
                                                            <Table.Cell floated={'right'} style={{width: "5%"}}>
                                                            <Button icon
                                                                    onClick={() => this.setState({
                                                                        openRemoveModal: true,
                                                                        id: el._id
                                                                    })}
                                                            >
                                                                <Icon color={"red"} name='trash alternate outline'/>
                                                            </Button>
                                                        </Table.Cell>
                                                        }
                                                    </Table.Row>
                                                )
                                            )
                                        }
                                    </Table.Body>

                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='4'>
                                                <Menu floated='right' pagination>
                                                    <Menu.Item
                                                        as='a'
                                                        icon
                                                        onClick={() => this.getTestsList()}
                                                    >
                                                        <Icon name='angle double left'/>
                                                    </Menu.Item>

                                                    {
                                                        this.state.prevPage ?
                                                            <Menu.Item
                                                                as='a'
                                                                onClick={() => this.getTestsList(this.state.prevPage)}
                                                            >
                                                                {this.state.prevPage}
                                                            </Menu.Item>
                                                            :
                                                            ''
                                                    }

                                                    <Menu.Item
                                                        style={{background: 'lightgray'}}
                                                    >
                                                        {this.state.page}
                                                    </Menu.Item>

                                                    {
                                                        this.state.nextPage ?
                                                            <Menu.Item
                                                                as='a'
                                                                onClick={() => this.getTestsList(this.state.nextPage)}
                                                            >
                                                                {this.state.nextPage}
                                                            </Menu.Item>
                                                            :
                                                            ''
                                                    }

                                                    <Menu.Item
                                                        as='a'
                                                        icon
                                                        onClick={() => this.getTestsList(this.state.totalPages)}
                                                    >
                                                        <Icon name='angle double right'/>
                                                    </Menu.Item>

                                                    <Menu.Item>
                                                        {`Страниц: ${this.state.totalPages !== undefined ? this.state.totalPages : 0}`}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {`Записей: ${this.state.totalDocs}`}
                                                    </Menu.Item>
                                                </Menu>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </div>
                }
            </div>
        )
    }

    private removeModalContent() {
        return this.state.openRemoveModal &&
            <Modal
                onClose={() => this.setState({openRemoveModal: false})}
                open={this.state.openRemoveModal}
                closeIcon
            >
                <Modal.Header>Вы уверены, что хотите удалить тип {Utils.vomode()!=AppMode.PATIENT ? 'документа' : 'анализа'}?</Modal.Header>
                <Modal.Actions>
                    <Button
                        content={"Удалить"}
                        onClick={this.removeTest}
                        positive
                    />
                    <Button
                        color={'grey'}
                        onClick={() => this.setState({openRemoveModal: false})}
                    >
                        Отмена
                    </Button>
                </Modal.Actions>
            </Modal>

    }
}

export default TestListPage;
