import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Dimmer, Form, FormButton, Grid, Header, Icon, InputOnChangeData, Loader, Message, Modal, Table } from "semantic-ui-react";
import agent from "../../agent";
import { BackupFile } from "./BackupFileModel";
import moment from "moment";
import { getTZ } from "../../utils/common";
import BackupFileLogViewModal from "./BackupFileLogViewModal";
import {hasRole, UserRole} from "../../helpers/UserRole";
const BackupFileList = () => {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState<BackupFile[]>([]);
    const [viewLogText, setViewLogText] = useState<string | undefined>();


    useEffect(() => {
        load().then();
    }, []);

    async function onLogPressed(file: BackupFile): Promise<void> {
        const logResponse = await agent.BackupProvider.log(file.filename.toString());
        setViewLogText(logResponse.result);
    }

    async function load() {
        let response = (await agent.BackupProvider.list());
        setItems(response.result);
    }

    return (
        <div>
            <Header as={"h4"}>Список резервных копий</Header>
            {hasRole(UserRole.ADMIN) && <Button icon onClick={() => load()} ><Icon name="refresh"/></Button>}
            <Table celled size={"small"} stackable={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Файл</Table.HeaderCell>
                        <Table.HeaderCell>Создан</Table.HeaderCell>
                        <Table.HeaderCell>Размер</Table.HeaderCell>
                        <Table.HeaderCell>Статус</Table.HeaderCell>
                        {/* <Table.HeaderCell width={1}>Журнал</Table.HeaderCell> */}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {items.map((el, index) =>
                        <Table.Row key={`key-${index}-${el.filename}`}>
                            <Table.Cell>{el.filename}</Table.Cell>
                            <Table.Cell>{moment(el.created).tz(getTZ()).format("DD.MM.YY HH:mm:ss")}</Table.Cell>
                            <Table.Cell textAlign='right'>{el.size}</Table.Cell>
                            <Table.Cell textAlign='center'><p style={{color: el.status == "Выполняется" ? "blue": "green"}}> {el.status}</p></Table.Cell>
                            {/* <Table.Cell>
                                <Button icon basic onClick={() => onLogPressed(el)}>
                                    <Icon name={"file text outline"}
                                        color="blue"
                                    />
                                </Button>
                            </Table.Cell> */}
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            {viewLogText && <BackupFileLogViewModal logText={viewLogText} onClose={function (success: Boolean): void {
                setViewLogText(undefined);
            }} />}
        </div>);
}

export default BackupFileList;