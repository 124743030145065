import React, {ChangeEvent, useEffect, useState} from "react";
import {Button, Dimmer, Form, InputOnChangeData, Loader, Modal} from "semantic-ui-react";

interface Props {
    logText: string;
    onClose: (success: Boolean) => void;
}

const BackupFileLogViewModal = (props: Props) => {
    const [enabled, setEnabled] = useState(false);
    const [changing, setChanging] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');

    return (
        <Modal
            onClose={() => props.onClose(false)}
            open={true}
            closeIcon>
            <Modal.Header>Журнал создания резервной копии</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.TextArea value={props.logText} style={{ minHeight: 400 }} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color={'grey'}
                    disabled={changing}
                    onClick={() => props.onClose(false)}>
                    Закрыть
                </Button>
            </Modal.Actions>
        </Modal>);
}

export default BackupFileLogViewModal;