import React, {ChangeEvent} from "react";
import styles from "./index.module.css";
import {Dept} from "../dashboard";
import {Button, Form, Icon, Loader, Menu, Modal, Table} from 'semantic-ui-react';
import agent, {PaginateOptions} from "../../agent";
import {Link} from "react-router-dom";
import {Utils} from "../../helpers/Utils";
import { AppMode, GeneralTitleCase, getGeneralTitle } from "../../utils/common";
import {hasRole, UserRole} from "../../helpers/UserRole";

interface Props {
    history?: History;
    match?: { url: string };

}

interface State {
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
    depts: Dept[],
    loading: boolean,
    openRemoveModal: boolean,
    id: string,
    sortColumn: string,
    sortOrder: 'ascending' | 'descending' | undefined,
    searchValue: string,
}

export interface DeptPaginationResponse {
    docs: Dept[],
    totalDocs: number,
    limit: number,
    page: number,
    totalPages: number,
    hasNextPage: boolean,
    nextPage: number,
    hasPrevPage: boolean,
    prevPage: number,
    pagingCounter: number,
}

class DeptList extends React.PureComponent<Props, State> {
    state: State = {
        totalDocs: 0,
        limit: 0,
        page: 1,
        totalPages: 1,
        hasNextPage: false,
        nextPage: 0,
        hasPrevPage: false,
        prevPage: 0,
        pagingCounter: 0,
        depts: [],
        loading: false,
        openRemoveModal: false,
        id: '',
        sortColumn: 'name',
        sortOrder: 'ascending',
        searchValue: '',
    };

    async componentDidMount() {
        await this.getDeptsList(1, 'name');
    };

    getDeptsList = async (goToPage?: number, sortBy?: string, searchString?: string): Promise<void> => {
        const searchTo: string = searchString ? searchString : '';

        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: 10,
            page: goToPage ? goToPage : 1,
            sort: sortBy,
            search: searchTo,
        };

        const response: DeptPaginationResponse = await agent.DeptProvider.getList(paginateOptions);

        if (response) {
            this.setState({
                depts: response.docs,
                totalDocs: response.totalDocs,
                limit: response.limit,
                page: response.page,
                totalPages: response.totalPages,
                hasNextPage: response.hasNextPage,
                nextPage: response.nextPage,
                hasPrevPage: response.hasNextPage,
                prevPage: response.prevPage,
                pagingCounter: response.pagingCounter,
                loading: false
            });
        } else {
            this.setState({loading: false});
        }
    };

    removedept = async () => {
        this.setState({loading: true});

        await agent.DeptProvider.remove(this.state.id);

        this.setState({
            id: '',
            openRemoveModal: false
        });

        await this.getDeptsList();
    };
    timeout: NodeJS.Timeout | undefined;

    deptsSearch = (): void => {
        this.setState({loading: true});
        this.getDeptsList(this.state.page, '', this.state.searchValue);
    }

    switchSortOrder = (orderString: string): string => {
        switch (this.state.sortOrder) {
            case 'ascending':
                this.setState({sortOrder: 'descending'});

                return `-${orderString}`;

            case 'descending':
                this.setState({sortOrder: 'ascending'});

                return orderString;

            default:
                return '';
        }
    };

    sortColumn = async (columnName: string): Promise<void> => {
        let sortString: string = columnName;

        if (this.state.sortColumn === columnName) {
            sortString = `${this.switchSortOrder(columnName)}`;
        } else {
            this.setState({sortOrder: 'ascending'});
        }

        this.getDeptsList(this.state.page, sortString, this.state.searchValue)
            .then(() =>
                this.setState({sortColumn: columnName}));
    };

    render() {
        const createLabel: string = `Добавить`;
        const headerLabel: string = `Список ${getGeneralTitle(GeneralTitleCase.departmantov)}`;
        const removeLabel: string = `УДАЛИТЬ`;
        const cancelLabel: string = `Отмена`;
        const confirmRemoveLabel: string = `Вы уверены, что хотите удалить ${getGeneralTitle(GeneralTitleCase.departmantim)}?`;

        const currentPath: string = this.props.match ? this.props.match.url : '';

        return (
            <div className={styles.table}>

                {
                    this.state.openRemoveModal &&
                    <Modal
                        onClose={() => this.setState({openRemoveModal: false})}
                        open={this.state.openRemoveModal}
                        closeIcon
                    >
                        <Modal.Header>{confirmRemoveLabel}</Modal.Header>
                        <Modal.Actions>
                            <Button
                                content={removeLabel}
                                onClick={this.removedept}
                                positive
                            />
                            <Button
                                color={'grey'}
                                onClick={() => this.setState({openRemoveModal: false})}
                            >
                                {cancelLabel}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                }
                    <div className={styles.header}>
                    {headerLabel}
                {hasRole(UserRole.ADMIN) &&
                    <Link to={`${currentPath}/create`}>
                        <Button
                            className={styles.createButton}
                            size={'large'}
                            color={'green'}
                            type={'submit'}
                        >
                            {createLabel}
                        </Button>
                    </Link>}
                </div>

                {
                    this.state.loading ?
                        <Loader active>Список загружается...</Loader>
                        :
                        <div className={styles.list}>
                                <Form>
                                    <Form.Group>
                                        <Form.Input
                                            placeholder='Поиск'
                                            iconPosition='left'
                                            icon={<Icon name='search'/>}
                                            value={this.state.searchValue}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                this.setState({searchValue: e.target.value})
                                            }}
                                        />
                                        <Form.Button primary content='Поиск' onClick={() => {
                                            this.deptsSearch()
                                        }}/>
                                        <Form.Button basic color='orange' content='Сбросить'
                                                     onClick={() => {
                                                         this.setState({searchValue: ""}, this.deptsSearch)
                                                     }}/>
                                    </Form.Group>
                                </Form>

                            <div className={styles.container}>
                                <Table celled selectable striped verticalAlign='middle' color={"teal"}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell
                                                sorted={this.state.sortColumn === 'name' ? this.state.sortOrder : undefined}
                                                onClick={() => this.sortColumn('name')}>Наименование {getGeneralTitle(GeneralTitleCase.departmanta)}</Table.HeaderCell>
                                            {hasRole(UserRole.ADMIN) && <Table.HeaderCell/>}
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            this.state.depts && this.state.depts.map((el, index) =>
                                                (
                                                    <Table.Row style={{cursor: "pointer"}}
                                                               key={`key-${index}-${el._id}`}>

                                                        <Table.Cell floated={'left'}>
                                                            {hasRole(UserRole.ADMIN) ?
                                                                <Link className={styles.nameColumn}
                                                                  to={`${currentPath}/${el._id}/edit`}>
                                                                {`${el.name}`}
                                                                </Link>
                                                                :
                                                                el.name
                                                            }
                                                        </Table.Cell>

                                                        {hasRole(UserRole.ADMIN) &&
                                                            <Table.Cell floated={'right'} style={{width: "5%"}}>
                                                                <Button icon
                                                                        onClick={() => this.setState({
                                                                            openRemoveModal: true,
                                                                            id: el._id
                                                                        })}
                                                                >
                                                                    <Icon color={"red"} name='trash alternate outline'/>
                                                                </Button>
                                                            </Table.Cell>
                                                        }
                                                    </Table.Row>
                                                )
                                            )
                                        }
                                    </Table.Body>

                                    <Table.Footer>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='4'>
                                                <Menu floated='right' pagination>
                                                    <Menu.Item
                                                        as='a'
                                                        icon
                                                        onClick={() => this.getDeptsList()}
                                                    >
                                                        <Icon name='angle double left'/>
                                                    </Menu.Item>

                                                    {
                                                        this.state.prevPage ?
                                                            <Menu.Item
                                                                as='a'
                                                                onClick={() => this.getDeptsList(this.state.prevPage)}
                                                            >
                                                                {this.state.prevPage}
                                                            </Menu.Item>
                                                            :
                                                            ''
                                                    }

                                                    <Menu.Item
                                                        style={{background: 'lightgray'}}
                                                    >
                                                        {this.state.page}
                                                    </Menu.Item>

                                                    {
                                                        this.state.nextPage ?
                                                            <Menu.Item
                                                                as='a'
                                                                onClick={() => this.getDeptsList(this.state.nextPage)}
                                                            >
                                                                {this.state.nextPage}
                                                            </Menu.Item>
                                                            :
                                                            ''
                                                    }

                                                    <Menu.Item
                                                        as='a'
                                                        icon
                                                        onClick={() => this.getDeptsList(this.state.totalPages)}
                                                    >
                                                        <Icon name='angle double right'/>
                                                    </Menu.Item>

                                                    <Menu.Item>
                                                        {`Страниц: ${this.state.totalPages !== undefined ? this.state.totalPages : 0}`}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {`Записей: ${this.state.totalDocs}`}
                                                    </Menu.Item>
                                                </Menu>
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Footer>
                                </Table>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default DeptList;
